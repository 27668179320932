<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">

            <div class="blog-list-row">
            <BreadCrumb :breadcrumbs="breadcrumbs" />

              <h1>Troubleshooting</h1>

              <a v-for="(data, i) in reverseDoc" :key="i" class="blog-list-item"
                 :href="data.link">
                <div class="blog-list-img">
                  <img :src="data.poster" :alt="data.title">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    <!-- {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }} -->
                    {{ data.date }}
                  </div>
                </div>
              </a>
            </div>
          </main>
        </div>
      </div>
    </div>

    <Footer />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';
import doc from '@/utlis/blogList.js';
import HeadNav from '@/components/HeadNav.vue';
import Footer from '@/components/Footer.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';


export default {
  name: 'Home',
  components: {
    HeadNav,
    Footer,
    BreadCrumb,
  },
  data () {
    return {
      pageName: 'home',
      doc: doc,
    };
  },
  mounted () {
    document.documentElement.lang = 'en';
   },
  computed: {
    reverseDoc () {
      return this.doc.bloglist.filter(item => item.category === 'Troubleshooting').reverse();
    },
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
